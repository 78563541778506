import React, { Fragment, useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import { IsMobileDevice, CleanArrayImages } from "../utils/functions";
import { useRecoilState } from "recoil";
import { isMobile, Mode } from "../recoil/atoms";
import ProjectsComp  from '../components/Projects/Projects' 
import SEO from "../components/seo";

const Projects = ({data}) => {

  const [images, setImages] = useState(false);
  const [, setIsMobile] = useRecoilState(isMobile);
  const [, setMode] = useRecoilState(Mode);

  useEffect(() => {
    setIsMobile(IsMobileDevice());
    setMode(localStorage.theme);
    setImages(CleanArrayImages(data));
  }, [data,setIsMobile,setMode]);

  return (
    <Fragment>
       <SEO title="Proyectos" description="Somos una startup con base en Buenos Aires que provee equipos on demand de alta calidad para trabajar en proyectos de innovación y tecnología, con foco en diseño UX, desarrollo de software e ingeniería."/>
        <Layout>
            {images && <ProjectsComp images={images} />}
        </Layout>
    </Fragment>
  );
};

export default Projects;

export const data = graphql`
  query Proyects {
    Messi644: allFile(filter: { relativeDirectory: { eq: "Projects/Budweiser/Messi644" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Blasfemia: allFile(filter: { relativeDirectory: { eq: "Projects/Blasfemia" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Escappe: allFile(filter: { relativeDirectory: { eq: "Projects/Escappe" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    JuntosPorSudamerica: allFile(filter: { relativeDirectory: { eq: "Projects/Conmebol/JuntosPorSudamerica" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Lollapalooza: allFile(filter: { relativeDirectory: { eq: "Projects/Budweiser/Lollapalooza" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Patagonia: allFile(filter: { relativeDirectory: { eq: "Projects/Patagonia" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 75, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;