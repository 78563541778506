import React, { useState, useEffect } from "react";
import styles from "./projects.module.scss";
import { Row } from "react-bootstrap";
import { Link } from "gatsby";
import ProjectCard from "./ProjectCard";
import { useRecoilValue } from "recoil";
import { ModeSelector } from "../../recoil/selectors";
import projects from "../../utils/projects";

const ProjectsComp = ({images}) => {

  const [uxDesign, setUxDesign] = useState(false);
  const [website, setWebsite] = useState(false);
  const [robotica, setRobotica] = useState(false);
  const [uiDesign, setUiDesign] = useState(false);
  const [desMobile, setDesMobile] = useState(false);
  const [filter, setFilter] = useState(false);
  const [displayProjects, setDisplayProjects] = useState([]);
  const mode = useRecoilValue(ModeSelector);

  useEffect(()=>{
    setDisplayProjects(projects(images))   
  },[images])

  const applyFilter = (filters) => {
    let filtered = []
    displayProjects.map((proj)=>{
      const found = proj.filters.some(r=> filters.includes(r))
      if(found)filtered.push(proj)
      return null
    })
    setDisplayProjects(filtered)
  };
 
  useEffect(()=>{
    if(filter){
      let filters = [];
      uxDesign && filters.push("UX");
      website && filters.push("Desarrollo Web");
      uiDesign && filters.push("UI");
      robotica && filters.push("robotica");
      desMobile && filters.push("Desarrollo Mobile");
      if(filters.length)applyFilter(filters)
      else{
        setDisplayProjects(projects(images))
      }
    }
  },[uxDesign,uiDesign,robotica,desMobile,website,images,filter])

  const clearFilter = () => {
    setUxDesign(false);
    setWebsite(false);
    setUiDesign(false);
    setRobotica(false);
    setDesMobile(false);
    setFilter(false);
    setDisplayProjects(projects(images))
  };


  return (
    <div className={styles.projectscontainer}>
      <Row style={{ width: "100%", textAlign: "left" }}>
        {" "}
        <small style={{ color: "gray" }}>
          {mode === "light" ? ( <Link style={{ color: "black" }} to="/">
            Home{" "}
          </Link>):( <Link style={{ color: "white" }} to="/">
            Home{" "}
          </Link>)}
          <span>/</span> Proyectos
        </small>
      </Row>
      <Row style={{ width: "100%", textAlign: "left", marginTop: "20px" }}>
        <h1>
          Proyectos<span>.</span>
        </h1>
      </Row>
      <div className={styles.textrow}>
        <div className={styles.textcontainer}>
          <p style={{ color: "gray" }}>
            Una selección de nuestros últimos proyectos
          </p>
        </div>
        <div className={styles.tagcol}>
          <small>Filtros</small>
          <div className={styles.tagcontainer}>
            <div
              role="button"
              tabIndex={0}
              className={uxDesign ? styles.tagActive : styles.tag}
              onClick={() => {
                setFilter(true);
                setUxDesign(!uxDesign);
              }}
              onKeyPress={()=>{
                setFilter(true);
                setUxDesign(!uxDesign);
              }}
            >
              Diseño UX
            </div>
            <div
              role="button"
              tabIndex={0}
              className={website ? styles.tagActive : styles.tag}
              onClick={() => {
                setFilter(true);
                setWebsite(!website);
              }}
              onKeyPress={()=>{
                setFilter(true);
                setWebsite(!website);
              }}
            >
              Desarrollo Web
            </div>
            <div
              role="button"
              tabIndex={0}
              className={desMobile ? styles.tagActive : styles.tag}
              onClick={() => {
                setFilter(true);
                setDesMobile(!desMobile);
              }}
              onKeyPress={()=>{
                setFilter(true);
                setDesMobile(!desMobile);
              }}
            >
              Desarrollo Mobile
            </div>
            <div
              role="button"
              tabIndex={0}
              className={uiDesign ? styles.tagActive : styles.tag}
              onClick={() => {
                setFilter(true);
                setUiDesign(!uiDesign);
              }}
              onKeyPress={()=>{
                setFilter(true);
                setUiDesign(!uiDesign);
              }}
            >
              Diseño UI
            </div>
            <div
              role="button"
              tabIndex={0}
              className={robotica ? styles.tagActive : styles.tag}
              onClick={() => {
                setFilter(true);
                setRobotica(!robotica);
              }}
              onKeyPress={()=>{
                setFilter(true);
                setRobotica(!robotica);
              }}
            >
              Robótica
            </div>
            <div
              role="button"
              tabIndex={0}
              className={styles.tag}
              onClick={() => {
                clearFilter();
              }}
              onKeyPress={()=>{
                clearFilter();
              }}
            >
              Todos
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cardrow}>

           {images && displayProjects.map((project, key) => {
            return (
              <ProjectCard
                key={key}
                project={project}
                prevProj={displayProjects[key - 1]}
                nextProj={displayProjects[key + 1]}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ProjectsComp;
