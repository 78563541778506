import React from "react";
import Img from "gatsby-image";
import styles from "./projects.module.scss";
import arrowViolet from "../../assets/images/arrowViolet.png";
import { Link } from "gatsby";

const ProjectCard = ({ project }) => {

  return (
    <div className={styles.cardcontainer}>
      <div className={styles.imgcont}>
        <div className={styles.effectcont}>
          <Link to={`/project/${project.slug}`}>
            <Img fluid={project.images['portada'].childImageSharp.fluid} alt="" />
          </Link>
        </div>
      </div>
      <div>
        <small style={{ color: "grey", fontSize: "9pt" }}>
          {project.tags.map((tag, key) => {
            if (key === 0) {
              return `${tag}`;
            } else {
              return ` | ${tag}`;
            }
          })}
        </small>
      </div>
      <div className={styles.titlerow}>
        <div className={styles.titlecont}>
          <h2>{project.title}</h2>
        </div>
        <div className="mb-3">
          <Link
            to={`/project/${project.slug}`}
            className={styles.ContainerLink}
          >
            <img alt="arrow" src={arrowViolet} className={styles.ArrowBefore} />
            <p>Ver más</p>
            <img alt="arrow" src={arrowViolet} className={styles.ArrowAfter} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
